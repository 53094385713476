import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import data from "../data/FirebaseData";
import BlogCard from "../component/BlogsCard";
import DocumentTitle from "../Services/DocumentTitle";
import Pagination from "../Services/Pagination";

const BlogList = () => {
  const cardsPerPage = 3;
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState();
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [blogsPerPage] = useState(cardsPerPage);

  const fetchData = async () => {
    try {
      const blogData = await data("article");
      setBlogs(blogData);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);

  // Pagination
  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = blogs.slice(indexOfFirstBlog, indexOfLastBlog);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  DocumentTitle("Blogs");

  return (
    <div className="container mx-auto pb-4 w-auto">
      <h6 className="text-2xl font-semibold">Blog List</h6>
      <div className="flex justify-center">
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
          {loading && <div>Loading...</div>}
          {!loading & (currentBlogs.length > 0)
            ? currentBlogs.map((blog) => (
                <Link to={`/article/${blog.id}`} key={blog.id}>
                  <BlogCard blog={blog} />
                </Link>
              ))
            : !loading && (
                <div className="col-span-full text-center">
                  No Records Found
                </div>
              )}
        </div>
      </div>
      {blogs.length > 0 && (
        <div className="flex justify-center mt-4">
          <Pagination
            cardsPerPage={cardsPerPage}
            totalCards={blogs.length}
            paginate={paginate}
            currentPage={currentPage}
          />
        </div>
      )}
    </div>
  );
};

export default BlogList;
