// import logo from './logo.svg';
import './App.css';
// import Header from "./component/Header"
import Layout from './layout/Layout';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import BlogDetail from './pages/Blog';
import PageNotFound from './pages/PageNotFound';
import BlogList from './pages/Blogs';
import About from './pages/About';
import ProjectDetail from './pages/Project';
import ProjectList from './pages/Projects';
import Contact from './pages/Contact';
import Github from './pages/Github';

// function App() {
// same as

const App = () => {
  return (
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
    

    // // Sikira wala
    
    // <div>
    //   {/* <Header /> */}
    //   <Layout>
    //     <p>
    //       <h2>
    //         Hello World
    //       </h2>
    //     </p>
    //   </Layout>
    // </div>

    // // Router code
    <>
      <Router>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />}/>
              <Route path="/articles" element={<BlogList />} />
              <Route path="/article/:id" element={<BlogDetail />} />
              <Route path="/projects" element={<ProjectList />} />
              <Route path="/project/:id" element={<ProjectDetail />} />
              <Route path='/about' element={<About />} />
              <Route path='/contact' element={<Contact />} />
              <Route path='/github' element={<Github />} />
              <Route path="*" element={<PageNotFound />} />
            </Route>
          </Routes>
      </Router>
    </>
  );
}

export default App;
