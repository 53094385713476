// const data = [
//     {
//       id: 1,
//       title: "Project 1",
//       author: "John Doe",
//       content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vitae feugiat nisl. Duis convallis mi non est placerat, sit amet posuere mauris malesuada."
//     },
//     {
//       id: 2,
//       title: "Project 2",
//       author: "Jane Smith",
//       content: "Integer cursus ultrices malesuada. Vivamus nec nibh varius, ultrices lectus et, tincidunt elit."
//     },
//     {
//       id: 3,
//       title: "Project 3",
//       author: "John Doe",
//       content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vitae feugiat nisl. Duis convallis mi non est placerat, sit amet posuere mauris malesuada."
//     },
//     {
//       id: 4,
//       title: "Project 4",
//       author: "Jane Smith",
//       content: "Integer cursus ultrices malesuada. Vivamus nec nibh varius, ultrices lectus et, tincidunt elit."
//     },
//   ];
  
//   export default data;

import { getDocs, collection } from "firebase/firestore"; 
import getDb from "../Services/Firebase";

const collection_name = "projects"

const data = async () => {
    const doc_refs = await getDocs(collection(getDb(), collection_name))

    const res = []
    // console.log(doc_refs);
    doc_refs.forEach(project => {
        res.push({
            id: project.id, 
            ...project.data()
        })
        // console.log(country.data())
    })

    return res
}

export default data;