import React from "react";

const BlogCard = ({ blog }) => {
  return (
    <div className="flex  w-[18em] sm-w-auto cursor-pointer flex-col border rounded-xl bg-articleBg text-textColor hover:shadow-lg">
        <div className="px-4 py-2 w-full">
          <img
            src=""
            className="h-[160px] rounded-b-xl rounded-t-lg"
            alt="img"
          />

          <div className="flex flex-col gap-y-2 p-4">
            <h2 className="text-2xl font-semibold">{blog.title}</h2>
            <p>By {blog.author}</p>
          </div>
        </div>
    </div>
  );
};

export default BlogCard;
