import React, { useState, useEffect } from "react";
import Header from "../component/Header-vs";
import Footer from "../component/Footer-vs";
import Sidebar from "../component/Sidebar-vs";
import Explorer from "../component/Explorer";
import Aside from "../component/Aside-vs";
import Tabs from "../component/Tabs-vs";
import { Outlet } from "react-router-dom";
// import Vscode from '../assets/vs-code'

const Layout = () => {
  const [contentUpdated, setContentUpdated] = useState(false);

  useEffect(() => {
    const contentDiv = document.getElementById("Content");
    const observer = new MutationObserver(() => {
      setContentUpdated((prev) => !prev);
    });

    observer.observe(contentDiv, {
      childList: true,
      subtree: true,
      characterData: true,
    });

    return () => observer.disconnect();
  }, []);

  return (
    <div className="overflow-hidden">
      <Header />
      <main className="row flex-nowrap bg-[#1e1e1e] text-white max-h-[calc(100vh-65px)] min-h-[calc(100vh-65px)]">
        <div className="col z-50 h-100 max-w-[60px] border-r ">
          <Sidebar />
        </div>
        <div className="col max-w-[220px] p-0 max-sm:hidden">
          <Explorer />
        </div>
        <div className=" col p-0 w-full bg-mainBg">
          <Tabs />
          <section className="bg-[#2d2d30]  overflow-y-scroll min-h-[calc(83vh+16px)] max-h-[calc(85vh-16px)] p-0 m-0">
            <div className="flex">
              <div id="Content" className="pt-2">
                <div className="z-50 w-[90vw] sm:w-[70vw]">
                  <Outlet />
                </div>
              </div>
              <Aside key={contentUpdated ? 'aside1' : 'aside2' } />
            </div>
          </section>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
