import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import data from "../data/ProjectData";
import DocumentTitle from "../Services/DocumentTitle";

const ProjectDetail = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const fetchData = async () => {
    setLoading(true);

    const res = await data();
    setProjects([...res]);
    setLoading(false);
  };
  useEffect(() => {
    fetchData();
    console.log(projects);
  }, []);
  // const project = projects.find(project => project.id === parseInt(id));
  const project = projects.find(
    (project) => project.id.toString() === id.toString()
  );
  {
    loading && <p>loading...</p>;
  }
  if (!project) {
    return <div>Project not found</div>;
  }
  // DocumentTitle("Project Page");
  return (
    <div className="p-4 lg:max-w-[70vw]">
      <h2>{project.title}</h2>
      <p>By {project.aurthor}</p>
      <p>{project.truncate}</p>
      <div className="flex justify-center flex-wrap gap-2 md:gap-[4rem] lg:gap-2 ">
        {project.contents.map((content) => (
          <p>{content}</p>
        ))}
      </div>
    </div>
  );
};

export default ProjectDetail;
