import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"; 

let db = false;
const getDb = () =>{
  if(!db){
    const firebaseConfig = {
      apiKey: "AIzaSyAJlQhxOqFlekoqP3IhpD8fPYL6ZqeUIR4",
      authDomain: "port-861ea.firebaseapp.com",
      projectId: "port-861ea",
      storageBucket: "port-861ea.appspot.com",
      messagingSenderId: "488529042055",
      appId: "1:488529042055:web:b59b507b7c9347e4a8b622",
      measurementId: "G-5W2D4MDJ9L"
    };
    
    const firebaseApp = initializeApp(firebaseConfig);
    db = getFirestore(firebaseApp);
  }
  return db;
}


export default getDb;