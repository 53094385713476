import React, { useState, useEffect } from 'react';

function Aside() {
  const [formattedHTML, setFormattedHTML] = useState('');

  useEffect(() => {
    const contentElement = document.getElementById('Content');
    if (contentElement) {
      const html = contentElement.innerHTML;
      const formatted = html
        .replace(/(<[^\/](.*?)>)/g, '\n$1')
        .replace(/(<\/(.*?)>)/g, '\n$1')
        .trim();
      setFormattedHTML(formatted);
    }
  }, [formattedHTML]);

  return (
    <aside id="aside" style={{zoom:'0.3'}} className="text-white p-0 m-0 bg-sidebarBg min-h-[calc(100vh-65px)] w-[12vw] pl-2 pt-2 lg:shadow-none sm:shadow-[#111111] sm:shadow-lg md:shadow-[#111111] md:shadow-lg max-sm:hidden ">
      <pre className='text-white overflow-hidden text-sm'>{formattedHTML}</pre>
    </aside>
  );
}

export default Aside;
