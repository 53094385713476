const TextInput = (props) => {
    return (
      <div className="form-group relative">
        <label htmlFor={props.name} className="leading-7 text-sm text-gray-400">{props.title}</label>
        <input
          type="text"
          onChange={props.handleChange}
          name={props.name}
          value={props.value}
          {...props}
          className="w-full bg-gray-800 rounded border border-gray-700 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-900 text-base outline-none text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
        />
        {props.errMessage !== "" && (
          <span className="text-danger">{props.errMessage}</span>
        )}
      </div>
    );
  };
  
  export default TextInput;
  