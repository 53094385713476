import react_icon from "../icons/react_icon.svg";
import html_icon from "../icons/html_icon.svg";
import css_icon from "../icons/css_icon.svg";
import js_icon from "../icons/js_icon.svg";
import json_icon from "../icons/json_icon.svg";
import markdown_icon from "../icons/markdown_icon.svg";
import { Link, useLocation } from "react-router-dom";

import "./test.css"

const TabsItems = [
  {
    name: "home.jsx",
    path: "/",
    icon: `${react_icon}`,
  },
  {
    name: "about.html",
    path: "/about",
    icon: `${html_icon}`,
  },
  {
    name: "contact.css",
    path: "/contact",
    icon: `${css_icon}`,
  },
  {
    name: "projects.js",
    path: "/projects",
    icon: `${js_icon}`,
  },
  {
    name: "articles.json",
    path: "/articles",
    icon: `${json_icon}`,
  },
  {
    name: "github.md",
    path: "/github",
    icon: `${markdown_icon}`,
  },
];
const Tabs = () => {
  const location = useLocation();
  return (
    <div className="flex overflow-x-hidden bg-tabsBg bg-[#1e1e1e] text-white overflow-x-scroll lg:overflow-x-hidden">
      {TabsItems.map((tab) => {
        return (
          <Link
            key={tab.name}
            to={`${tab.path}`}
            className={`flex ${
              location.pathname === tab.path
                ? "border-t border-t-[#007acc] border-t-2 border-l border-r bg-[#2d2d30] bg-tabActiveBg"
                : "border bg-tabBg"
            }   min-w-max gap-x-1  border-tabBorder  px-3 text-textColor z-999  md:py-1`}
          >
            <img src={tab.icon} height={20} width={20} alt="react-icon" />
            <p className=" text-lg font-medium">{tab.name}</p>
          </Link>
        );
      })}
    </div>
  );
}

export default Tabs;