// import React, { useState, useEffect } from 'react';
// import RepoCard from '../component/RepoCard';

// const Github =() => {
//   const [repos, setRepos] = useState([]);
//   const [error, setError] = useState(null);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     fetch(`https://api.github.com/users/noob-hash/repos`)
//       .then(response => {
//         if (response.ok) {
//           return response.json();
//         }
//         throw new Error('Failed to fetch repositories');
//       })
//       .then(data => {
//         console.log(data)
//         setRepos(data);
//         setLoading(false);
//       })
//       .catch(error => {
//         setError(error);
//         setLoading(false);
//       });
//   }, []);

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   if (error) {
//     return <div>Error: {error.message}</div>;
//   }

//   return (
//     <div>
//       <h1 className='pb-2'>Explore my Github repositories</h1>
//       <div className="flex justify-center flex-wrap gap-2 md:gap-[4rem] lg:gap-2 pb-4 ">
//         {repos.map(repo => (
//           <RepoCard key={repo.id} repo={repo} />
//         ))}
//       </div>
//     </div>
//   );
// }

// export default Github;

import React, { useState, useEffect } from "react";
import RepoCard from "../component/RepoCard";
import Pagination from "../Services/Pagination";
import DocumentTitle from "../Services/DocumentTitle";

const Github = () => {
  const cardsPerPage = 6;
  const [repos, setRepos] = useState([]);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [reposPerPage] = useState(cardsPerPage);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`https://api.github.com/users/noob-hash/repos`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Failed to fetch repositories");
      })
      .then((data) => {
        setRepos(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);
  DocumentTitle("Github");

  const indexOfLastRepo = currentPage * reposPerPage;
  const indexOfFirstRepo = indexOfLastRepo - reposPerPage;
  const currentRepos = repos.slice(indexOfFirstRepo, indexOfLastRepo);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="container mx-auto pb-4">
      <h1 className="text-3xl font-semibold mb-4">
        Explore my Github repositories
      </h1>
      <div className="flex justify-center">
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
          {currentRepos.map((repo) => (
            <RepoCard key={repo.id} repo={repo} />
          ))}
        </div>
      </div>
      <div className="flex justify-center mt-4">
        <Pagination
          cardsPerPage={cardsPerPage}
          totalCards={repos.length}
          paginate={paginate}
          currentPage={currentPage}
        />
      </div>
    </div>
  );
};

export default Github;
