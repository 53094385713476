import React from 'react';
import { Link } from 'react-router-dom';
import DocumentTitle from '../Services/DocumentTitle';

const PageNotFound = () => {
  DocumentTitle("Page Not Found");

  return (
    <div className="flex flex-col h-[80vh] items-center justify-center text-white" >
      <h1 className="text-4xl font-bold mb-4">404 - Not Found</h1>
      <p className="mb-8">Sorry, the page you are looking for does not exist.</p>
      <Link to="/" className="flex items-center justify-center bg-white text-black px-4 py-2 rounded hover:bg-gray-300">
        Go to Home Page
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
        </svg>
      </Link>
    </div>
  );
}

export default PageNotFound;
