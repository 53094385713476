import React from 'react';
import { useParams } from 'react-router-dom';
import blogs from '../data/BlogData';
import DocumentTitle from '../Services/DocumentTitle';

const BlogDetail = () => {
  const { id } = useParams();
  const blog = blogs.find(blog => blog.id === parseInt(id));

  if (!blog) {
    return <div>Blog not found</div>;
  }
  DocumentTitle(blog.title)
  return (
    <div className="p-4 lg:max-w-[70vw]">
      <h2>{blog.title}</h2>
      <p>By {blog.author}</p>
      <p>{blog.content}</p>
    </div>
  );
}

export default BlogDetail;