import React, { useState } from "react";
import { FaClone } from "react-icons/fa"; // Importing clone icon from Font Awesome
import Github from "../icons/Github";

function RepoCard({ repo }) {
  const [ariaLabel, setAriaLabel] = useState("Copy text to clipboard");
  const [showLabel, setShowLabel] = useState(false);

  const handleCopyToClipboard = () => {
    const copyText = document.getElementById("github_url");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(copyText.value);

    setAriaLabel("Copied");
    setShowLabel(true);

    setTimeout(() => {
      setShowLabel(false);
      setAriaLabel("Copy text to clipboard");
    }, 2000);
  };
  return (
    <div className="flex  w-[18em] sm-w-auto cursor-pointer flex-col border rounded-xl bg-articleBg text-textColor hover:shadow-lg">
      <div className="px-4 py-2">
        <h3 className="text-xl font-semibold">{repo.name}</h3>
        <div className="flex items-center mt-2">
          <img
            className="w-6 h-6 rounded-full mr-2"
            src={repo.owner.avatar_url}
            alt={`${repo.owner.login}'s Avatar`}
          />
          <p className="text-sm text-gray-400">Owner: {repo.owner.login}</p>
        </div>
        <p className="text-gray-200 mt-2 h-[10em]">
          {repo.description || "No description available"}
        </p>
        {showLabel && (
          <div className="absolute z-50 mt-4 bg-white text-black py-2 px-4 rounded">
            Copied
          </div>
        )}
        <div className="flex gap-2 justify-center">
          <input
            type="text"
            className="hidden"
            id="github_url"
            name="github_url"
            value={repo.clone_url}
          />
          <a
            href={repo.html_url}
            aria-label="View on Github"
            target="_blank"
            rel="noopener noreferrer"
            className="mt-4 flex items-center justify-center bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
          >
            <Github className="mr-2" />
          </a>
          <button
            onClick={handleCopyToClipboard}
            aria-label={ariaLabel}
            className="mt-4 flex items-center justify-center bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
          >
            <FaClone className="mr-2" /> Copy url
          </button>
        </div>
      </div>
    </div>
  );
}

export default RepoCard;
