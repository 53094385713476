import React from "react";
import { Link } from "react-router-dom";
import data from "../data/ProjectData";
import ProjectCard from "../component/ProjectCard";
import DocumentTitle from "../Services/DocumentTitle";
import { useState, useEffect } from "react";

const ProjectList = () => {
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const fetchData = async () => {
    setLoading(true);

    const res = await data();
    setProjects([...res])
    setLoading(false);
  }
    useEffect(() => {
      // setTimeout(() => {
      //   fetchData();
      //   console.log(projects);
      // }, 500);
      fetchData();
      // console.log(projects);
    }, []);
  DocumentTitle("Projects");
  return (
    <div className="pb-4 pl-2 ">
      <h6 className=" text-2xl font-semibold ">Project List</h6>
      <div className="flex justify-center flex-wrap gap-2 md:gap-[4rem] lg:gap-2 ">
        {loading && <p>loading...</p>}
        {projects.length > 0 &&
          projects.map((project) => (
            <Link to={`/project/${project.id}`} key={project.id} class>
              <ProjectCard project={project} />
            </Link>
          ))}
        {!loading && projects.length === 0 && <div>No Records Found</div>}
      </div>
    </div>
  );
};

export default ProjectList;
