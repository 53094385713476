import React from "react";
import "./Styles.css";
import DocumentTitle from "../Services/DocumentTitle";
import Vscode from "../assets/vs-code";
import Typewriter from "typewriter-effect";


const Home = () => {

  DocumentTitle("Home");
  return (
    <>
      <div className="flex pl-2 mt-[30vh] justify-space-around text-white">
        <div className="container max-sm:mt-0">
          <h1 className="font-bold" style={{ fontSize: "3rem" }}>
            Subin Sedhai
          </h1>
          <div className="animate mt-2">
            <Typewriter
              options={{
                strings: [
                  "ML & Automation Developer",
                  "Backend & API Developer",
                  "IoT Developer"
                ],
                autoStart: true,
                loop: true,
              }}
            />
          </div>
        </div>
      </div>
      <Vscode />
    </>
  );
};

export default Home;
