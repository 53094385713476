const About = () => {
  return (
    <div className="flex justify-center">
      <div className="p-2 lg:max-w-[60vw] ">
        <div className="text-white">
          <span className="text-2xl font-semibold">
            &lt;h2&gt;About Me&lt;/h2&gt;
          </span>
          <br />
          <ul className="list-disc list-inside p-4">
            &lt;li&gt;<b className="text-lg">Developer&lt;/li&gt;</b>
            <p className="pl-[1.5rem]">
              As a developer, I love bringing ideas to life through code.
              Whether it's crafting elegant websites, building robust backend
              systems, or developing intuitive mobile apps, I enjoy the creative
              process of solving problems and delivering user-centric solutions.
            </p>
            <br />
            &lt;li&gt;
            <b className="text-lg">Machine Learning Enthusiast&lt;/li&gt;</b>
            <p className="pl-[1.5rem]">
              My fascination with machine learning stems from its ability to
              extract valuable insights from data and make intelligent
              predictions. From implementing classification algorithms to
              exploring deep learning architectures, I'm passionate about
              harnessing the power of AI to solve real-world problems.
            </p>
            <br />
            &lt;li&gt;<b className="text-lg">Automation Advocate&lt;/li&gt;</b>
            <p className="pl-[1.5rem]">
              I strongly believe in the transformative potential of automation
              to streamline workflows and enhance efficiency. Whether it's
              automating routine tasks with scripts or designing intricate
              workflows with orchestration tools, I'm dedicated to simplifying
              processes and maximizing productivity.
            </p>
            <br />
          </ul>
        </div>
      </div>
    </div>
  );
};

export default About;
