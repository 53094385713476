import React from 'react';

const ProjectCard = ({ project }) => {
  return (

    <div className="flex  w-[20em] sm-w-auto cursor-pointer flex-col border rounded-xl bg-articleBg text-textColor hover:shadow-lg">
      <img src="" className=" h-[160px] rounded-b-xl rounded-t-lg" alt="" />

      <div className="flex flex-col gap-y-2 p-4">
        <h2 className=" text-2xl font-semibold">{project.title}</h2>
        <p>By {project.aurthor}</p>
      </div>
    </div>
  );
}

export default ProjectCard;