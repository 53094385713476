/* eslint-disable react/prop-types */
// import vscode from "../assets/icons/vscode.ico";
import { FaMinus, FaRegWindowClose } from "react-icons/fa";

const Header = () => {
  return (
    <header className={`  bg-titlebarBg text-textColor bg-[#1e1e1e] text-white border-b`}>
      <nav
        className={` mx-3 flex h-8 items-center justify-between  text-center`}
      >
        <div className="mr-auto flex  items-center">
          <img src="../../vsc.png" height={20} width={20} alt="VsCode icon" />
        </div>
        <div className="md:ml-auto md:mr-2 md:gap-x-3">
          <div className="flex gap-x-3">
            <i class="fas fa-arrow-left"></i>
            <i class="fas fa-arrow-right"></i>
          </div>
        </div>
        <div className="md:mr-auto border border-1 border-gray-400 w-50 rounded bg-[#2d2d30] ">
          <span className=" md:mr-auto">Portfolio Site</span>
        </div>
        <div className="ml-auto flex gap-x-2">
          <div className="h-3 w-3 md:h-4 md:w-4">
            <FaMinus />
          </div>
          <div className="h-3 w-3 border-2 md:h-4 md:w-4"></div>
          <div className="h-3 w-3 md:h-4 md:w-4">
            <FaRegWindowClose />
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
