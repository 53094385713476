import { getDocs, collection } from "firebase/firestore"; 
import getDb from "../Services/Firebase";

const data = async (collection_name) => {
    const doc_refs = await getDocs(collection(getDb(), collection_name))

    const res = []
    doc_refs.forEach(info => {
        res.push({
            id: info.id, 
            ...info.data()
        })
    })
    return res
}

export default data;